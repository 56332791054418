import React from 'react';
import Layout from '@components/layout';
import { LANG_SE_SV } from '@/src/common/consts/lang';
import Seo from '@components/seo';
import SalesforceSmsTag from '@components/salesforceSmsTag/SalesforceSmsTag';
import { MARKET_SE } from '@/src/common/consts/market';
import { THEME_WHITE } from '@/src/common/consts/theme';
import CricleAppIllustration from '@assets/illustration_circle_app.svg';
import Segment from '@components/segment';

const SmsInbjudanPage = () => (
  <Layout
    dataTheme={THEME_WHITE}
    headerWitdh="header"
    headerStyle="flex--center"
    lang="se"
    showFooter
  >
    <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_SE} />

    <Seo title="Ladda ner" language="sv" />

    <section data-theme={THEME_WHITE}>
      <div className="container container--xs flex flex--column flex--center text--center margin-bottom--xxl margin-top--xs">
        <div className="salesforce-sms-page" style={{ overflow: 'visible' }}>

          <div className="salesforce-sms-page__text-container">
            <img src={CricleAppIllustration} alt="Patient illustration" height="130px" className="margin-bottom--sm" />
            <h2 className="text--lg margin-bottom--xs">Ladda ner Joint Academy</h2>
            <p className="margin-bottom--sm">
              Ange din referens och ditt mobilnummer så får
              du ett SMS med länk för att ladda ner Joint Academy appen.
            </p>
          </div>

          <div className="salesforce-sms-page__sms-container">
            <SalesforceSmsTag market={MARKET_SE} lang={LANG_SE_SV} placeholder="Ange referens (person eller klinik)" showAccounts showContacts />
          </div>
        </div>
      </div>
    </section>

  </Layout>
);

export default SmsInbjudanPage;
